export const couponCodes = {
    MIDCON10OFF: 10,
    BIT15OFF: 15,
    CINCYAI: 30,
    KASCOPE: 100,
    EDUFREE: 100,
    UCNETIMPACT: 75,
    PARTOF1819: 100,
    POWER: 100,
    MIDWESTPARTNER24: 100
}