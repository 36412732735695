import React, { useEffect, useState } from "react";
import { PopupButton } from '@typeform/embed-react';
import { Nav, Tab, Accordion, Modal, Button } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import VideoBg from '../assets/video/video_bg.mp4';
import { DataHeader } from "./DataHeader";
import Textslider2 from "./textslider2";
import DropdownWithContent from "./about_select";
import 'add-to-calendar-button';

const DataHackathon = () => {

    useEffect(() => {

        AOS.init({
            easing: 'ease-out-back',
            duration: 1000
        });

    }, [])

    const options6 = {
        items: 4,
        nav: false,
        dots: false,
        autoplay: false,
        slideTransition: 'linear',
        autoplayTimeout: 5000,
        autoplaySpeed: 2500,
        autoplayHoverPause: false,
        margin: 80,
        responsiveClass: true,
        responsive: {
            0: {
                margin: 20,
                items: 1
            },
            480: {
                items: 2,
                margin: 25
            },
            575: {
                items: 3,
                margin: 30
            },
            768: {
                items: 4,
                margin: 50,
            },
            991: {
                margin: 40,
            },
            1025: {
                margin: 60,
            },
            1200: {
                margin: 60,
            }
        },
    };
    const [openMoreQsMod, setOpenMoreQsMod] = useState(false)
    const closeMoreQsMod = () => {
        setOpenMoreQsMod(false)
    }
    const hanldeOpenMoreQsMod = () => {
        setOpenMoreQsMod(true)
    }

    const [isClassAdded, setIsClassAdded] = useState(false);

    const handleClick = () => {
        setIsClassAdded(true);
    };

    useEffect(() => {
        if (isClassAdded) {
            const timer = setTimeout(() => {
                setIsClassAdded(false);
            }, 3000); 
            return () => clearTimeout(timer);
        }
    }, [isClassAdded]);

    return (
        <div className="data_hack">
            <Modal show={openMoreQsMod} onHide={(e) => closeMoreQsMod(e, this)} dialogClassName="animationDialog" className="asc-popup2" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>

                    <div id="mc_embed_signup">
                        <form action="https://disruptionnow.us14.list-manage.com/subscribe/post?u=ae99ac9a4fafd8609771595ff&amp;id=de0b7cbc51&amp;f_id=00d582e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                            <div id="mc_embed_signup_scroll">
                                <h4>For more questions, please feel free to contact us at</h4>
                                <a href="mailto:hackathon@disruptionnowmedia.com" className="btn">
                                    <span>hackathon@disruptionnowmedia.com</span>
                                </a>
                            </div>
                        </form>
                        <Button className="clsbtn" onClick={closeMoreQsMod}>X</Button>
                    </div>

                </Modal.Body>

            </Modal>
            <DataHeader />
            <div className="data_banner" id="data_ban">
                <div className="ban_img">
                    <video width="100%" height="100%" loop autoplay="autoplay" muted>
                        <source src={require("../assets/video/main_banner.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="ban_cnt">
                    <div className="container">
                        <div className="flx">
                            <div className="txt">
                                <h2>Building a better, more
                                    <span> equitable</span>
                                    <span>future of data.</span>
                                </h2>
                                <button>
                                    <span>ADD TO CALENDAR</span>

                                    <add-to-calendar-button name="ADD TO CALENDAR" startDate="today+8" endDate="today+21" location="World Wide Web" options="'Google','Apple','Outlook.com','Yahoo','iCal'" timeZone="" iCalFileName="Reminder-Event" listStyle="modal">
                                    </add-to-calendar-button>
                                </button>
                            </div>
                            <img src={'/assets/img/data_hack2024/ban_tree.png'} alt="" className="imgcenterimg" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="video_sec">
                <div className="container">
                    <div className="d-flex">
                        <div className="cont_sec">
                            <div className="blk">
                                <div className="icon">
                                    <img src={'/assets/img/data_hack2024/red_loc.png'} alt="" className="imgcenterimg" />
                                </div>
                                <div className="txt">
                                    <span>1819 INNOVATION
                                        HUB -UC</span>
                                    <p>2900 Reading Rd, Cincinnati, OH 45206</p>
                                </div>
                            </div>
                            <div className="blk">
                                <div className="icon">
                                    <img src={'/assets/img/data_hack2024/red_calender.png'} alt="" className="imgcenterimg" />
                                </div>
                                <div className="txt">
                                    <p>Aug 24 to Sept 6 </p>
                                </div>
                            </div>
                        </div>
                        <div className="vid_sec">
                            <video width="100%" height="100%" loop autoplay="autoplay" muted>
                                <source src={require("../assets/video/data_banner.mp4")} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dt_future">
                <div className="container">
                    <div className="htd">
                        <h2>The Future of data hackathon</h2>
                    </div>
                    <div className="bdy">
                        <div className="img">
                            <img src={'/assets/img/data_hack2024/future_img.png'} alt="" className="imgcenterimg" />
                        </div>
                        <div className="txt">
                            <p>The Future of Data Hackathon is FREE for all college or university students. Those who are non-students MUST be registered as a MIDWESTCON conference participant to be eligible for the Future of Data Hackathon. It is designed to align with the core themes of MidwestCon. Participants collaborate to creatively harness AI, blockchain, and data visualization for social impact - fostering meaningful partnerships between designers, entrepreneurs, policy experts, and tech developers. By integrating these efforts, the hackathon supports MidwestCon's goal of advancing technology and policy for a more equitable and innovative future.</p>
                            <div className="btns">
                                <PopupButton id='oLC16sAO' className="btn">Register Now</PopupButton>
                                <a href="#tracks" className="btn">LEARN NOW</a>
                            </div>
                            <h4>Key Dates</h4>
                            <ul>
                                <li>
                                    <span>Hybrid Event Dates:</span>
                                    August 24-September 6
                                </li>
                            </ul>
                            <p>The Future of Data Hackathon is a hybrid event. All activities take place at the 1819 Innovation Hub and virtually.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="abt_sec" id="data_about">
                <div className="container">
                    <h2>About</h2>
                    <div className="abt_cnt">
                        <div className="blks">
                            <h4>Mission/Vision</h4>
                            <p>Empowering the next generation ​of innovators and entrepreneurs ​to create a better, more ​equitable future of data.</p>
                        </div>
                        <div className="blks">
                            <h4>Organizers</h4>
                            <p>Organized by students from ​midwestern universities ​including Purdue, Ohio State, ​University of Michigan, ​University of Cincinnati, and ​more, in partnership with ​MidwestCon.</p>
                        </div>
                        <div className="blks">
                            <h4>Audience</h4>
                            <p>Open to students across the ​Midwest, entrepreneurs, and ​software developers ​interested in building a better ​future of data. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tracks_chag" id="tracks">
                <div className="head">
                    <div className="container">
                        <h2>Tracks & Challenges</h2>
                        <p>The Future of Data Hackathon doesn't have tracks. <br /> Instead it has roots, branches, and leaves!</p>
                    </div>
                </div>
            </div>
            <div className="qus" id="qus">
                <div className="container">
                    <div id="left-tabs-example2">
                        <div className="tab-content">
                            <div eventKey="How" className="animated animatedFadeInUp fadeInUp">
                                <div className="box">
                                    <div className="lft_txt">
                                        <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">LEAVES</h3>
                                        <p>Represents the specific themes or topics that participants can choose to focus on within each branch. </p>
                                        <p>The current themes include:</p>
                                        <ul>
                                            <li>Gaming</li>
                                            <li>Security</li>
                                            <li>Finance</li>
                                            <li>Social Impact</li>
                                        </ul>
                                        <p>Participants can choose to work on projects related to these themes and compete for prizes in each category.</p>
                                    </div>
                                    <div className="img">
                                        <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">LEAVES</h3>
                                        <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">BRANCHES</h3>
                                        <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">ROOTS</h3>
                                        <img src="/assets/img/tree.png" alt="" className="me-0 animated animatedFadeInUp fadeInUp" />
                                    </div>
                                    <div className="rft_txt">
                                        <div className="top">
                                            <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">BRANCHES</h3>
                                            <p>Represents the different technology branches that participants can explore during the hackathon. There are three branches to choose from:</p>
                                            <ul>
                                                <li>AI Branch</li>
                                                <li>Blockchain Branch</li>
                                                <li>Visualization Branch </li>
                                            </ul>
                                        </div>
                                        <div className="btm">
                                            <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">ROOTS</h3>
                                            <p>Represents the foundational elements of the hackathon.</p>
                                            <p>Two main roots (tracks):</p>
                                            <ul>
                                                <li>Software Development Root</li>
                                                <li>Business/Creative Root</li>
                                            </ul>
                                            <p>These roots serve as the basis for participants to build their projects and ideas upon.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="resource">
                <div className="container">
                    <h2>Resources</h2>
                    <div className="txt">
                        <h4>Mentorship</h4>
                        <p>We ore proud to host a toom of experienced professionals in dota technologies and entrepreneurship, who will help tooms novigate technical challenges, refine their ideas, and execute thoir projects effectively.</p>
                        <h5>Key Features of Our Mentorship Program:</h5>
                        <ul>
                            <li>Personalized Support: Each team is assigned a specific mentor based on their project, ensuring personalized attention ond tailored support.</li>
                            <li>Flexible Interaction: Our mentorship program accommodates both remote and on-site availability.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="scdle">
                <div className="container">
                    <h2>Schedule</h2>
                    <div className="txt">
                        <h5>The Future of Data Hackathon is a hybrid event. All activities take place at the 1819 Innovation Hub AND virtually.</h5>
                        <p>All judging and workshops will be available virtually as well, but conference sessions are only available in-person.</p>
                    </div>
                </div>
            </div>
            {/* <div className="data_agenda" id="agenda">
                <div className="container">
                    <div className="agenda_sec">
                        <div className="img">
                            <img src={'/assets/img/data_hack2024/agenda_img.jpg'} alt="" className="imgcenterimg" />
                        </div>
                        <div className="txt">
                            <h3>Agenda</h3>
                            <div className="blks">
                                <h5>August 24: Opening Ceremony & Workshops</h5>
                                <p>This segment includes introductory workshops and initial track-specific breakouts.</p>
                            </div>
                            <div className="blks">
                                <h5>August 25-September 3: Virtual Work & Learning</h5>
                                <p>The virtual learning series and mentor check-ins will take place virtually.</p>
                            </div>
                            <div className="blks">
                                <h5>September 4: Conference Day!</h5>
                                <p>A full day at the Midwestcon Conference on September 4th will be devoted to future of data-related speakers. Judging for the hackathon will begin on September 4th.</p>
                            </div>
                            <div className="blks">
                                <h5>September 6: Closing Ceremony</h5>
                                <p>Winners will get to present their pitches at the closing ceremony of Midwestcon, presenting in front of hundreds of attendees, speakers and mentors.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="in_prices">
                <div className="container">
                    <h2>$40,000 IN PRIZES</h2>
                    <h3>Prize Categories</h3>
                    <a href="https://future-of-data.devpost.com/?ref_feature=challenge&ref_medium=discover" target="_blank">Check out our Devpost!</a>
                </div>
            </div>
            <div className="sft_box">
                <div className="container">
                    <h2>What to Build</h2>
                    <div className="d-flex">
                        <div className="box">
                            <div className="txt">
                                <h4>Software Development</h4>
                                <p>Some software experience is encouraged for this competition.</p>
                                <p>Build software projects to innovate using AI, Blockchain, or Data Visualization to solve real-world challenges </p>
                            </div>
                            <div className="img">
                                <img src={'/assets/img/data_hack2024/sft_icon1.png'} alt="" className="imgcenterimg" />
                            </div>
                        </div>
                        <div className="box">
                            <div className="txt">
                                <h4>Pitch Competition</h4>
                                <p>No technical experience is needed for the pitch competition.</p>
                                <p>Explore entrepreneurship and present  your vision for a better world using AI, Blockchain, or Data Visualization.</p>
                            </div>
                            <div className="img">
                                <img src={'/assets/img/data_hack2024/sft_icon2.png'} alt="" className="imgcenterimg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub_sec">
                <div className="container">
                    <div className="bdys">
                        <div className="boxs">
                            <h3>
                                <img src={'/assets/img/data_hack2024/up_arrow.png'} alt="" className="imgcenterimg" />
                                SOFTWARE DEVELOPMENT
                            </h3>
                            <div className="box">
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon1.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Project Description</h4>
                                        <ul>
                                            <li>Provide a detailed explanation of your project, including Its purpose, functionality, and Impact.</li>
                                            <li>Clearly describe how your solution contributes to doto equity.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon2.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Technical Details</h4>
                                        <ul>
                                            <li>Include Information about the technologies used and how they ore Implemented.</li>
                                            <li>Explain the architecture of your solution and ony unique technical challenges you faced and overcome.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon3.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Demo Video</h4>
                                        <ul>
                                            <li>Create a short video (3-5 minutes) demonstrating your project.</li>
                                            <li>The video should highlight the key features, functionality, ond user experience of your solution.</li>
                                            <li>Exploin the problem your project oddresses and how it offers a solution.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon4.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Source Code</h4>
                                        <ul>
                                            <li>Provide a link to your project's source code repository (e.g., GitHub).</li>
                                            <li>Ensure that the repository is public and includes a clear README file with instructions on how to set up ond run your project.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon5.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Team Information</h4>
                                        <ul>
                                            <li>List all team members and their roles in the project.</li>
                                            <li>Include any relevant background information or expertise that contributed to the development of the project.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon6.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Additional Documentation</h4>
                                        <ul>
                                            <li>Include any additional documentation that con help judges understond your project better, such as user manuals, design documents, or reseorch references.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="boxs">
                            <h3>
                                PITCH COMPETITION
                                <img src={'/assets/img/data_hack2024/up_arrow_blue.png'} alt="" className="imgcenterimg" />
                            </h3>
                            <div className="box">
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon7.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Pitch Deck</h4>
                                        <ul>
                                            <li>Create a comprehensive pitch deck that outlines your project idea, market potential, and business model.</li>
                                            <li>The pitch deck should include slides on the problem statement, solution overview, market onolysis. competitive landscope, revenue model, and go-to-market strategy.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon6.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Executive Summary</h4>
                                        <ul>
                                            <li>Provide a concise executive summary of your project, highlighting the key aspects ond value proposition.</li>
                                            <li>Explain how your solution addresses a significant problem and its potential impact on the morket.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon3.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Pitch Video</h4>
                                        <ul>
                                            <li>Record a short video (3-5 minutes) where you present your pitch to potential investors or stokeholders.</li>
                                            <li>The video should cloorly orticulate your project's vision, market opportunity, ond why it stonds out.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon8.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Market Research</h4>
                                        <ul>
                                            <li>Include any relevant market research dota that supports your project's feasibility ond potential success.</li>
                                            <li>Highlight key insights and statistics that demonstrate the need for your solution.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon10.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Financial Projections</h4>
                                        <ul>
                                            <li>Provide financial projections, including revenue forecasts, funding requirements, ond expected return on investment.</li>
                                            <li>Clearly exploin the assumptions behind your projections.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bks">
                                    <div className="icon">
                                        <img src={'/assets/img/data_hack2024/sd_icon5.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <div className="txt">
                                        <h4>Team Information</h4>
                                        <ul>
                                            <li>List all team members ond their roles in the project.</li>
                                            <li>Include ony relevont background Information or expertise that contributed to the development of the project.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub_sec2">
                <div className="container">
                    <div className="head">
                        <h2>Submission Requirements on <span>Devpost</span></h2>
                        <p>Below are the submission requirements for the two root tracks: <span>SOFTWARE DEVELOPMENT and BUSINESS/CREATIVE PITCH COMPETITION.</span></p>
                    </div>
                </div>
            </div>
            <div className="wht_build">
                <div className="container">
                    <h4>Submission Requirements</h4>
                    <h5>Hackers participating in the Future of Data Hackathon are expected to create innovative solutions in the fields of blockchain, artificial intelligence, and data visualization, focusing on "building a better, more equitable future of data."</h5>
                    <p>Submissions should be made on Devpost, and participants must follow the guidelines below for both the
                        software development track and the pitch competition track</p>
                    <div className="bks">
                        <div className="img">
                            <img src={'/assets/img/data_hack2024/build_img1.jpg'} alt="" className="imgcenterimg" />
                        </div>
                        <div className="txt">
                            <h3>1. Innovative Solutions</h3>
                            <ul>
                                <li>Develop projects that leverage blockchain,
                                    Al, or data visualization technologies.</li>
                                <li>Focus on creating solutions that address real-world problems and promote a better, more equitable future.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bks">
                        <div className="txt">
                            <h3>2. Choose Your Branch</h3>
                            <ul>
                                <li>Each Prize Track has three 'core questions.' For a project to submit under that track, they must answer at least one of these core questions.</li>
                                <li>These questions are determined by our sponsors and can be found on the prize category list.</li>
                            </ul>
                            <h3>3. Choose Your Bounties</h3>
                            <ul>
                                <li>Take on targeted challenges set by sponsors to win additional prizes. These bounties focus on specific problems or technologies, these bounties provide extra prizes for their completion.</li>
                            </ul>
                        </div>
                        <div className="img">
                            <img src={'/assets/img/data_hack2024/build_img2.jpg'} alt="" className="imgcenterimg" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="text_sec">
                <div className="container">
                    <p>
                        The Future of Data Hackathon at Midwest Con is designed to align with the core themes of Midwestcon. Participants collaborate to creatively harness Al, blockchain and data visualization for social impact- fostering meaningful partnerships between designers, entrepreneurs, policy experts and tech developers. By integrating these efforts, the hackathon supports Midwest Con's goal of advancing technology and policy for a more equitable and innovative future.</p>
                </div>
            </div> */}



            <div className="jud_sec">
                <div className="container">
                    <div className="d-flex">
                        <div className="txt">
                            <h3>Judging Criteria</h3>
                            <h4>Innovation</h4>
                            <p>Creativity and originality of the solution.</p>
                            <h4>Impact</h4>
                            <p>Potential to create significant positive change.</p>
                            <h4>Execution</h4>
                            <p>Level of technical execution and problem-solving.</p>
                            <h4>Relevance</h4>
                            <p>Clority of market or sociol fit.</p>
                            <PopupButton id='oLC16sAO' className="btn">Register Now</PopupButton>
                        </div>
                        <div className="img">
                            <img src={'/assets/img/data_hack2024/jud_img.jpg'} alt="" className="imgcenterimg" />
                        </div>
                    </div>
                </div>
            </div>




            {/* <div className="price_cate">
                <div className="container">
                    
                </div>
            </div> */}

            {/* <div className="reg_sec">
                <div className="container">
                    <h2>Registration</h2>
                    <p>Secure your spot at the future of data hackathon</p>
                    <PopupButton id='oLC16sAO'>Registration Form</PopupButton>
                    <div className="blks">
                        <div className="txt">
                            <p>The Future of Data hackathon is FREE to all students enrolled in a college or university. Those who are non-students MUST be registered as a Midwestcon conference participant in order to be eligible to participate in the Future of Data Hackathon.</p>
                            <p>To be clear, students do NOT need to purchase a Midwestcon ticket to participate, but are encouraged to do so. More infromation about Midwestcon and the ticket purchase link is included in the registration form.</p>
                        </div>
                        <div className="img">
                            <img src={'/assets/img/data_hack2024/reg_img.jpg'} alt="" className="imgcenterimg" />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="faq_sec">
                <div className="container">
                    <h2>Faq <img src={'/assets/img/data_hack2024/down_arrow.png'} alt="" className="imgcenterimg" /></h2>
                    <Accordion className="faq-accor animated2 animatedFadeInUp fadeInUp">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is a hackathon?</Accordion.Header>
                            <Accordion.Body>
                                <p>A hackathon is an event where student hackers bring their passion for technology to create a new project coded from scratch. It's a chance to collaborate and create something unique in any number of forms - an app, a robot, a website! This hackathon is unique in that it also has a business pitch aspect as well.<br /><br />So, you can choose whether you want to code your project or come up with a business plan! <br /><br />At The Future of Data Hackathon, participants are provided with opportunities to learn while building something they're proud of!</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>When is the hackathon?</Accordion.Header>
                            <Accordion.Body>
                                <p>The Future of Data Hackathon will be from <span>AUGUST 24 2024 to SEPTEMBER 6 2024</span>.</p>
                                <p>FULL AGENDA IS PENDING.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Do I have to be technical?</Accordion.Header>
                            <Accordion.Body>
                                <p><span>No!</span> We have there will opportunities on the creative side. We may also recommend you team up with a technical partner or you can design a process.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Do I have to attend in person?</Accordion.Header>
                            <Accordion.Body>
                                <p>The Future of Data Hackathon is a hybrid event.</p>
                                <p>Even though majority of the event will be virtual, the opening ceremony and workshops will be held at <span>1819 Innovation Hub of University of Cincinnati</span>, located at 2900 Reading Rd, Cincinnati, OH 45206.</p>
                                <p>Unfortunately, we <span>do not</span> offer travel reimbursements at this time.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Does it cost anything?</Accordion.Header>
                            <Accordion.Body>
                                <p>The Future of Data Hackathon is <span>free</span> for all Midwestcon Attendees as well as students enrolled at any accredited university.</p>
                                <p>Swag, prizes, and great memories are all included with this completely Free of cost! (:</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>What if I’m new to hackathons?</Accordion.Header>
                            <Accordion.Body>
                                <p>Our hackathon has been designed and curated to accommodate participants of all levels. </p>
                                <p>We have support on standby, so <span>don’t be shy</span>! This is the perfect hackathon to be your first.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>How do I form a team?</Accordion.Header>
                            <Accordion.Body>
                                <p><span>Teams are formed at the event.</span></p>
                                <p>If you already have a team, please make sure every individual in your team is registered before the event! The <span>maximum team size is 4</span>.</p>
                                <p>If you don’t have a team, that's okay! We’ll host a team formation event after the opening ceremony, so stay tuned.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>What are the hackathon tracks?</Accordion.Header>
                            <Accordion.Body>
                                <p>The Future of Data Hackathon doesn’t have tracks. Instead it has <span>roots</span>, <span>branches</span>, and <span>leaves</span>!</p>
                                <p>Click ‘How?’ <a href="#qus"><span>HERE</span></a> for more info.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Will we get support?</Accordion.Header>
                            <Accordion.Body>
                                <p><span>Yes</span>, there will be multiple workshops and lab sessions where participants will learn all about the future of data.</p>
                                <p>There will also be a <span>large variety of mentors</span> available at the event both in-person and virtually to support during the event.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>What should I bring?</Accordion.Header>
                            <Accordion.Body>
                                <p>Please bring a:</p>
                                <ul>
                                    <li><span>valid ID</span> for registration (student or license)</li>
                                    <li><span>computer/laptop</span> to hack on <span>with chargers</span></li>
                                    <li><span>positive attitude (:</span></li>
                                </ul>
                                <p>We also recommend bringing basic toiletries (toothbrush, deodorant, etc), a pillow/blanket (if you choose to nap). Please note that Ethernet access will be limited, so please plan to use a Wi-Fi connection.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>Is there a minimum age for participants?</Accordion.Header>
                            <Accordion.Body>
                                <p>All coders must be <span>at least 18 years of age!</span></p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <div className="spon_sec">
                <div className="container">
                    <h2>Sponsors</h2>
                    <p>A special thank you to our sponsors for making <br />this hackathon possible.</p>
                    <div className="spns_sec">
                        <div className="spn_blk">
                            <div className="logo">
                                <img src={'/assets/img/data_hack2024/data_spns3.png'} alt="" className="imgcenterimg" />
                            </div>
                            <div className="logo">
                                <img src={'/assets/img/data_hack2024/1819logo.png'} alt="" className="imgcenterimg" />
                            </div>
                            <div className="logo">
                                <img src={'/assets/img/data_hack2024/data_spns4.png'} alt="" className="imgcenterimg" />
                            </div>
                        </div>
                        <div className="spn_blk">
                            <div className="logo">
                                <img src={'/assets/img/data_hack2024/tembo_logo.png'} alt="" className="imgcenterimg" />
                            </div>
                            <div className="logo">
                                <img src={'/assets/img/data_hack2024/Coinbase_logo.png'} alt="" className="imgcenterimg" />
                            </div>
                            <div className="logo">
                                <img src={'/assets/img/data_hack2024/Cyrannus_logo.png'} alt="" className="imgcenterimg" />
                            </div>
                            <div className="logo">
                                <img src={'/assets/img/data_hack2024/jobs-ohio.png'} alt="" className="imgcenterimg" />
                            </div>
                        </div>
                    </div>
                    <h5>An extra thank you to Chris Bradley for his support.</h5>
                </div>
            </div>
            <footer id="contact" className={isClassAdded ? 'highlighted' : ''}>
                <div className="container">
                    <h2>Contact Us</h2>
                    <ul>
                        <li>
                            <a href="mailto: hackathon@disruptionnowmedia.com" target="_blank">Hackathon@disruptionnowmedia.com</a>
                        </li>
                        <li>Sponsorship@disruptionnowmedia.com</li>
                        <li>1819 Innovation Hub, Cincinnati OH</li>
                    </ul>
                    <div className="social_icons">
                        <ul>
                            <li>
                                <a href="#">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fa-brands fa-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            <div className="linked_btns">
                <div className="container">
                    <div className="d-flex">
                        <PopupButton id='oLC16sAO' className="btn">Registration</PopupButton>
                        <a href="/agenda" target='_blank' className="nav-link btn">Schedule</a>
                        <PopupButton id="bBO2U1RW" className="btn">Sponsors</PopupButton>
                        <button className="btn" onClick={handleClick}>Contact</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataHackathon;