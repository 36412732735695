import React, { useEffect, useState } from "react";
import { PopupButton } from '@typeform/embed-react';
import { Nav, Tab, Accordion, Modal, Button } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import VideoBg from '../assets/video/video_bg.mp4';
import { DataHeader } from "./DataHeader";
import Textslider2 from "./textslider2";

const DataHackathon = () => {

    useEffect(() => {

        AOS.init({
            easing: 'ease-out-back',
            duration: 1000
        });

    }, [])

    const options6 = {
        items: 4,
        nav: false,
        dots: false,
        autoplay: false,
        slideTransition: 'linear',
        autoplayTimeout: 5000,
        autoplaySpeed: 2500,
        autoplayHoverPause: false,
        margin: 80,
        responsiveClass: true,
        responsive: {
            0: {
                margin: 20,
                items: 1
            },
            480: {
                items: 2,
                margin: 25
            },
            575: {
                items: 3,
                margin: 30
            },
            768: {
                items: 4,
                margin: 50,
            },
            991: {
                margin: 40,
            },
            1025: {
                margin: 60,
            },
            1200: {
                margin: 60,
            }
        },
    };
    const [openMoreQsMod, setOpenMoreQsMod] = useState(false)
    const closeMoreQsMod = () => {
        setOpenMoreQsMod(false)
    }
    const hanldeOpenMoreQsMod = () => {
        setOpenMoreQsMod(true)
    }
    return (
        <div className="data_hack">
            <Modal show={openMoreQsMod} onHide={(e) => closeMoreQsMod(e, this)} dialogClassName="animationDialog" className="asc-popup2" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>

                    <div id="mc_embed_signup">
                        <form action="https://disruptionnow.us14.list-manage.com/subscribe/post?u=ae99ac9a4fafd8609771595ff&amp;id=de0b7cbc51&amp;f_id=00d582e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                            <div id="mc_embed_signup_scroll">
                                <h4>For more questions, please feel free to contact us at</h4>
                                <a href="mailto:hackathon@disruptionnowmedia.com" className="btn">
                                    <span>hackathon@disruptionnowmedia.com</span>
                                </a>
                            </div>
                        </form>
                        <Button className="clsbtn" onClick={closeMoreQsMod}>X</Button>
                    </div>

                </Modal.Body>

            </Modal>
            <DataHeader />
            <div className="data_banner" id="data_ban">
                <div className="ban_img">
                    <img src="/assets/img/data_banner.png" alt="" />
                </div>
                <div className="ban_cnt">
                    <div className="container">
                        <Textslider2 />
                        <p className="me-0 animated2 animatedFadeInUp fadeInUp">
                            AUG 24-SEPT 6 2024
                            <span>1819 INNOVATION HUB, CINCINNATI, OH</span>
                        </p>
                        <div className="tiwrap">
                            <img src="/assets/img/Crypto-Club.png" alt="" />
                            <h4>HOSTS</h4>
                            <img src="/assets/img/ucai_img.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="winner" id="apply">
                <div className="video_bg">
                    <video loop autoPlay muted id="video">
                        <source src={VideoBg} type="video/mp4" />
                    </video>
                </div>
                <div className="cnt">
                    <div className="container">
                        <p className="animated animatedFadeInUp fadeInUp">Join hundreds of builders in the Midwest for a week of <span>hacking</span> emerging tech solutions, <span>insightful workshops</span>, <span>networking</span>, and a <span>Demo Day</span>.  If you have never hacked before or are not technical, no worries! We have beginner tracks for AI, blockchain, and visualization.</p>
                        <h2 className="animated2 animatedFadeInUp fadeInUp">
                            Winners
                            <span>gon’ win!</span>
                        </h2>
                        <h4 className="animated2 animatedFadeInUp fadeInUp">And you can be one of them.</h4>
                        <div className="btns">
                            <PopupButton id="oLC16sAO" className="btn animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                                <span className="animated2 animatedFadeInUp fadeInUp">APPLY NOW</span>
                            </PopupButton>
                            <a href="#faq" className="btn animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                                <span className="animated2 animatedFadeInUp fadeInUp">FAQs</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="agenda">
                <div className="container">
                    <div className="tpt">
                        <div className="lft">
                            <h2 className="me-0 animated animatedFadeInUp fadeInUp">
                                Future of Data <br />Hackathon
                                <span>Agenda</span>
                            </h2>
                        </div>
                        <div className="rft">
                            <img src="/assets/img/comming_soon.gif" alt="" className="me-0 animated2 animatedFadeInUp fadeInUp" />
                        </div>
                    </div>
                    <ul className="me-0 animated animatedFadeInUp fadeInUp">
                        <li>
                            The full hackathon is running from <span>AUG 24-SEPT 4</span>.
                        </li>
                        <li>
                            All participants/teams will present their projects at Demo Day on <span>SEPT 5</span>.
                        </li>
                        <li>
                            Winners will be exclusively invited to present their ideas in front of investors and top industry leaders in tech and policy at <a href="/"><span className="text-white">MidwestCon</span></a> on <span>SEPT 6</span>.
                        </li>
                    </ul>
                </div>
            </div> 
            <div className="qus" id="qus">
                <div className="container">
                    <Tab.Container id="left-tabs-example2">
                        <Nav variant="pills">
                            <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                <Nav.Link eventKey="What">What?</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                <Nav.Link eventKey="How">How?</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                <Nav.Link eventKey="When">When?</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="What" className="animated animatedFadeInUp fadeInUp">
                                <p>The Future of Data Hackathon is a transformative tech event in Cincinnati, hosted by the <span>UC Crypto Club</span> in collaboration with <span>MidwestCon</span>.</p>
                                <p>Unlike other hackathons, this event is organized into a unique tree structure that allows participants in a highly personalized experience.</p>
                                <p>The hackathon offers specialized “roots” in AI, blockchain, and visualization, allowing participants to delve deep into these cutting-edge technologies. Additionally, the hackathon features <span>workshops</span>, <span>networking</span> opportunities, and <span>mentorship</span> programs to help participants develop innovative solutions and pitches.</p>
                                <p>With a focus on inclusivity, the event welcomes both technical and non-technical participants, offering beginner tracks for both business and software hackers.</p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="How" className="animated animatedFadeInUp fadeInUp">
                                <div className="title">
                                    <h4>The Future of Data hackathon is organized like a tree:</h4>
                                </div>
                                <div className="box">
                                    <div className="lft_txt">
                                        <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">LEAVES</h3>
                                        <p>Represents the specific themes or topics that participants can choose to focus on within each branch. </p>
                                        <p>The current themes include:</p>
                                        <ul>
                                            <li>Gaming</li>
                                            <li>Security</li>
                                            <li>Finance</li>
                                            <li>Social Impact</li>
                                        </ul>
                                        <p>Participants can choose to work on projects related to these themes and compete for prizes in each category.</p>
                                    </div>
                                    <div className="img">
                                        <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">LEAVES</h3>
                                        <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">BRANCHES</h3>
                                        <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">ROOTS</h3>
                                        <img src="/assets/img/tree.png" alt="" className="me-0 animated animatedFadeInUp fadeInUp" />
                                    </div>
                                    <div className="rft_txt">
                                        <div className="top">
                                            <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">BRANCHES</h3>
                                            <p>Represents the different technology branches that participants can explore during the hackathon. There are three branches to choose from:</p>
                                            <ul>
                                                <li>AI Branch</li>
                                                <li>Blockchain Branch</li>
                                                <li>Visualization Branch </li>
                                            </ul>
                                        </div>
                                        <div className="btm">
                                            <h3 className="me-0 animated2 animatedFadeInUp fadeInUp">ROOTS</h3>
                                            <p>Represents the foundational elements of the hackathon.</p>
                                            <p>Two main roots (tracks):</p>
                                            <ul>
                                                <li>Software Development Root</li>
                                                <li>Business/Creative Root</li>
                                            </ul>
                                            <p>These roots serve as the basis for participants to build their projects and ideas upon.</p>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="When" className="animated animatedFadeInUp fadeInUp">
                                <p>The Future of Data Hackathon will be from <span>AUGUST 24 2024</span> to <span>SEPTEMBER 6 2024</span>.</p>
                                <p>The hackathon is split into three parts:</p>
                                <p><span>1. Opening Ceremony & Workshops:</span> August 24. This segment includes introductory workshops and initial track-specific breakouts.</p>
                                <p><span>2. Virtual Work Time:</span> Scheduled August 25 - September 4. Various virtual events and mentor check-ins will take place virtually.</p>
                                <p><span>3. Judging & Closing Ceremony:</span> Scheduled for September 5-6 during the MidwestCon Conference, where final projects are evaluated and winners announced.</p>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            <div className="faq" id="faq">
                <div className="hd">
                    <div className="container">
                        <h2>FAQ</h2>
                    </div>
                </div>
                <div className="bdy _desk">
                    <div className="container">
                        <div className="flex">
                            <Tab.Container id="left-tabs-example" >
                                <div className="lft">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="one">What is a hackathon?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="two">When is the hackathon?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="three">Do I have to be technical?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="four">Do I have to attend in person?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="five">Does it cost anything?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="six">What if I’m new to hackathons?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="seven">How do I form a team?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="eight">What are the hackathon tracks?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="nine">Will we get support?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="ten">What should I bring?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="eleven">Is there a minimum age for participants?</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item className="animated2 animatedFadeInUp fadeInUp">
                                            <Nav.Link eventKey="twl">Agenda</Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </div>
                                <div className="rft">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="one" className="animated animatedFadeInUp fadeInUp">
                                            <p>A hackathon is an event where student hackers bring their passion for technology to create a new project coded from scratch. It's a chance to collaborate and create something unique in any number of forms - an app, a robot, a website! This hackathon is unique in that it also has a business pitch aspect as well.<br/><br/>So, you can choose whether you want to code your project or come up with a business plan! <br/><br/>At The Future of Data Hackathon, participants are provided with opportunities to learn while building something they're proud of!</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="two" className="animated animatedFadeInUp fadeInUp">
                                            <p>The Future of Data Hackathon will be from <span>AUGUST 24 2024 to SEPTEMBER 6 2024</span>.</p>
                                            <p>FULL AGENDA IS PENDING.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="three" className="animated animatedFadeInUp fadeInUp">
                                            <p><span>No!</span> We have there will opportunities on the creative side. We may also recommend you team up with a technical partner or you can design a process.</p>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="four" className="animated animatedFadeInUp fadeInUp">
                                            <p>The Future of Data Hackathon is a hybrid event.</p>
                                            <p>Even though majority of the event will be virtual, the opening ceremony and workshops will be held at <span>1819 Innovation Hub of University of Cincinnati</span>, located at 2900 Reading Rd, Cincinnati, OH 45206.</p>
                                            <p>Unfortunately, we <span>do not</span> offer travel reimbursements at this time.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="five" className="animated animatedFadeInUp fadeInUp">
                                            <p>The Future of Data Hackathon is <span>free</span> for all Midwestcon Attendees as well as students enrolled at any accredited university.</p>
                                            <p>Swag, prizes, and great memories are all included with this completely Free of cost! (:</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="six" className="animated animatedFadeInUp fadeInUp">
                                            <p>Our hackathon has been designed and curated to accommodate participants of all levels. </p>
                                            <p>We have support on standby, so <span>don’t be shy</span>! This is the perfect hackathon to be your first.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="seven" className="animated animatedFadeInUp fadeInUp">
                                            <p><span>Teams are formed at the event.</span></p>
                                            <p>If you already have a team, please make sure every individual in your team is registered before the event! The <span>maximum team size is 4</span>.</p>
                                            <p>If you don’t have a team, that's okay! We’ll host a team formation event after the opening ceremony, so stay tuned.</p>                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="eight" className="animated animatedFadeInUp fadeInUp">
                                            <p>The Future of Data Hackathon doesn’t have tracks. Instead it has <span>roots</span>, <span>branches</span>, and <span>leaves</span>!</p>
                                            <p>Click ‘How?’ <a href="#qus" aria-label=""><span>HERE</span></a> for more info.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="nine" className="animated animatedFadeInUp fadeInUp">
                                            <p><span>Yes</span>, there will be multiple workshops and lab sessions where participants will learn all about the future of data.</p>
                                            <p>There will also be a <span>large variety of mentors</span> available at the event both in-person and virtually to support during the event.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ten" className="animated animatedFadeInUp fadeInUp">
                                            <p>Please bring a:</p>
                                            <ul>
                                                <li><span>valid ID</span> for registration (student or license)</li>
                                                <li><span>computer/laptop</span> to hack on <span>with chargers</span></li>
                                                <li><span>positive attitude (:</span></li>
                                            </ul>
                                            <p>We also recommend bringing basic toiletries (toothbrush, deodorant, etc), a pillow/blanket (if you choose to nap). Please note that Ethernet access will be limited, so please plan to use a Wi-Fi connection.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="eleven" className="animated animatedFadeInUp fadeInUp">
                                            <p>All coders must be <span>at least 18 years of age!</span></p>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="twl" className="animated animatedFadeInUp fadeInUp">
                                            <p>MidwestCon is the premier conference showcasing the Midwest's strengths in policy, innovation, and social impact. We aim to connect builders, innovators, community leaders, and policymakers to drive economic and social impact.  We are the SXSW of the Midwest. </p>
                                        </Tab.Pane> */}
                                    </Tab.Content>
                                    <div className="btns">
                                        <PopupButton id="oLC16sAO" className="btn animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                                            <span className="animated2 animatedFadeInUp fadeInUp">APPLY NOW</span>
                                        </PopupButton>
                                        {/* <a href="mailto:vennemdp@mail.uc.edu" className="btn animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                                            <span className="animated2 animatedFadeInUp fadeInUp">MORE QUESTIONS?</span>
                                        </a> */}
                                        <button type="button" className="btn animated animatedFadeInUp fadeInUp" data-aos="fade-up" onClick={hanldeOpenMoreQsMod}>
                                            <span className="animated2 animatedFadeInUp fadeInUp">MORE QUESTIONS?</span>
                                        </button>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
                <div className="bdy _mob">
                    <div className="container">
                        <Accordion className="faq-accor animated2 animatedFadeInUp fadeInUp">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is a hackathon?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A hackathon is an event where student hackers bring their passion for technology to create a new project coded from scratch. It's a chance to collaborate and create something unique in any number of forms - an app, a robot, a website! This hackathon is unique in that it also has a business pitch aspect as well.<br/><br/>So, you can choose whether you want to code your project or come up with a business plan! <br/><br/>At The Future of Data Hackathon, participants are provided with opportunities to learn while building something they're proud of!</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>When is the hackathon?</Accordion.Header>
                                <Accordion.Body>
                                    <p>The Future of Data Hackathon will be from <span>AUGUST 24 2024 to SEPTEMBER 6 2024</span>.</p>
                                    <p>FULL AGENDA IS PENDING.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Do I have to be technical?</Accordion.Header>
                                <Accordion.Body>
                                    <p><span>No!</span> We have there will opportunities on the creative side. We may also recommend you team up with a technical partner or you can design a process.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Do I have to attend in person?</Accordion.Header>
                                <Accordion.Body>
                                    <p>The Future of Data Hackathon is a hybrid event.</p>
                                    <p>Even though majority of the event will be virtual, the opening ceremony and workshops will be held at <span>1819 Innovation Hub of University of Cincinnati</span>, located at 2900 Reading Rd, Cincinnati, OH 45206.</p>
                                    <p>Unfortunately, we <span>do not</span> offer travel reimbursements at this time.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Does it cost anything?</Accordion.Header>
                                <Accordion.Body>
                                    <p>The Future of Data Hackathon is <span>free</span> for all Midwestcon Attendees as well as students enrolled at any accredited university.</p>
                                    <p>Swag, prizes, and great memories are all included with this completely Free of cost! (:</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>What if I’m new to hackathons?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Our hackathon has been designed and curated to accommodate participants of all levels. </p>
                                    <p>We have support on standby, so <span>don’t be shy</span>! This is the perfect hackathon to be your first.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>How do I form a team?</Accordion.Header>
                                <Accordion.Body>
                                    <p><span>Teams are formed at the event.</span></p>
                                    <p>If you already have a team, please make sure every individual in your team is registered before the event! The <span>maximum team size is 4</span>.</p>
                                    <p>If you don’t have a team, that's okay! We’ll host a team formation event after the opening ceremony, so stay tuned.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>What are the hackathon tracks?</Accordion.Header>
                                <Accordion.Body>
                                    <p>The Future of Data Hackathon doesn’t have tracks. Instead it has <span>roots</span>, <span>branches</span>, and <span>leaves</span>!</p>
                                    <p>Click ‘How?’ <a href="#qus"><span>HERE</span></a> for more info.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>Will we get support?</Accordion.Header>
                                <Accordion.Body>
                                    <p><span>Yes</span>, there will be multiple workshops and lab sessions where participants will learn all about the future of data.</p>
                                    <p>There will also be a <span>large variety of mentors</span> available at the event both in-person and virtually to support during the event.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>What should I bring?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Please bring a:</p>
                                    <ul>
                                        <li><span>valid ID</span> for registration (student or license)</li>
                                        <li><span>computer/laptop</span> to hack on <span>with chargers</span></li>
                                        <li><span>positive attitude (:</span></li>
                                    </ul>
                                    <p>We also recommend bringing basic toiletries (toothbrush, deodorant, etc), a pillow/blanket (if you choose to nap). Please note that Ethernet access will be limited, so please plan to use a Wi-Fi connection.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>Is there a minimum age for participants?</Accordion.Header>
                                <Accordion.Body>
                                    <p>All coders must be <span>at least 18 years of age!</span></p>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item eventKey="11">
                                <Accordion.Header>Agenda</Accordion.Header>
                                <Accordion.Body>
                                    <p>MidwestCon is the premier conference showcasing the Midwest's strengths in policy, innovation, and social impact. We aim to connect builders, innovators, community leaders, and policymakers to drive economic and social impact.  We are the SXSW of the Midwest. </p>
                                </Accordion.Body>
                            </Accordion.Item> */}
                        </Accordion>
                        <div className="btns">
                            <PopupButton id="oLC16sAO" className="btn animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                                <span className="animated2 animatedFadeInUp fadeInUp">APPLY NOW</span>
                            </PopupButton>
                            {/* <a href="mailto:vennemdp@mail.uc.edu" className="btn animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                                <span className="animated2 animatedFadeInUp fadeInUp">MORE QUESTIONS?</span>
                            </a> */}
                            <button type="button" className="btn animated animatedFadeInUp fadeInUp" data-aos="fade-up" onClick={hanldeOpenMoreQsMod}>
                                <span className="animated2 animatedFadeInUp fadeInUp">MORE QUESTIONS?</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sponsors" id="spons">
                <div className="container">
                    <div className="top animated animatedFadeInUp fadeInUp">
                        <h2>Sponsors & Partners</h2>
                        <p>We’re grateful to the sponsors and partners who share the same conviction for disruption as we do.</p>
                    </div>
                    <div className="bdy">
                        <div className="slider animated2 animatedFadeInUp fadeInUp">
                            <OwlCarousel className='owl-theme6' loop {...options6}>
                                <div className="item">
                                    <img src="/assets/img/data_spns1.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="/assets/img/data_spns3.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="/assets/img/data_spns2.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="/assets/img/data_spns4.png" alt="" />
                                </div>
                            </OwlCarousel>
                        </div>
                        <div className="btns">
                            <PopupButton id="bBO2U1RW" className="btn animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                                <span className="animated2 animatedFadeInUp fadeInUp">SPONSOR US</span>
                            </PopupButton>
                        </div>
                    </div>
                    <div className="fyt animated animatedFadeInUp fadeInUp">
                        <p>...and many more coming!</p>
                    </div>
                </div>
            </div>
            <footer>
                <div className="container">
                    <div className="ft">
                        <h4>get social</h4>
                        <div className='mwc-social-links'>
                            <a className="nav-link" href="https://twitter.com/MidwestCon_" target="_blank" rel="noreferrer"><i class="fa-brands fa-x-twitter"></i></a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default DataHackathon;