import React, { Component, useEffect } from 'react';
import { Route, BrowserRouter, Routes, useNavigate } from 'react-router-dom'  
import { Midwestcon } from './Component/midwestcon';
import { NewMidwestcon } from './Component/midwestcon_new';
import { Events } from './Component/events';
import { Archive2023 } from './Component/archive-2023';
import { Header } from './Component/Header';
import { Footer } from './Component/Footer';
import DataHackathon from './Component/data_hackathon2024';
import DataHackathonNew from './Component/data_hackathon2024_new';
import Agenda from './Component/agenda';


const NotFoundPage = () => {
    const navigate = useNavigate();
  
    // Redirect to the home page when the component mounts
    useEffect(() => {
      navigate('/', { replace: true });
    }, [navigate]);
  
    return <div>Page Not Found. Redirecting to Home...</div>;
  };
function AuthCluster() {
        return (
          
               
            <BrowserRouter >
                                {/* <Header/> */}

                <Routes>
                <Route exact path="/" element={<NewMidwestcon/>} />
                <Route exact path="/datahackathon-2024" element={<DataHackathon/>} />
                <Route exact path="/datahackathonNew-2024" element={<DataHackathonNew/>} />
                {/* <Route exact path="/archive-2023" element={<Archive2023/>} /> */}
                <Route exact path="/agenda" element={<Agenda/>} />
                <Route path="*" element={<NotFoundPage/>} />
                </Routes>
                {/* <Footer/> */}

            </BrowserRouter>  
 
    
        )
    
}

export default AuthCluster