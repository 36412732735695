import React from "react";
import { Nav, Tab, Accordion, Modal, Button } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { DataHeader } from "./DataHeader";

const Agenda = () => {
    return (
        <div className="data_hack agenda_page">
            <DataHeader />
            <div className="agenda">
                <div className="container">
                    <h2>Agenda</h2>
                    <ul>
                        <li>
                            <div className="blks">
                                <h5>August 24: Opening Ceremony & Workshops</h5>
                                <p>This segment includes introductory workshops and initial track-specific breakouts.</p>
                            </div>
                        </li>
                        <li>
                            <div className="blks">
                                <h5>August 25-September 3: Virtual Work & Learning</h5>
                                <p>The virtual learning series and mentor check-ins will take place virtually.</p>
                            </div>
                        </li>
                        <li>
                            <div className="blks">
                                <h5>September 4: Conference Day!</h5>
                                <p>A full day at the Midwestcon Conference on September 4th will be devoted to future of data-related speakers. Judging for the hackathon will begin on September 4th.</p>
                            </div>
                        </li>
                        <li>
                            <div className="blks">
                                <h5>September 6: Closing Ceremony</h5>
                                <p>Winners will get to present their pitches at the closing ceremony of Midwestcon, presenting in front of hundreds of attendees, speakers and mentors.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <footer id="contact">
                <div className="container">
                    <h2>Contact Us</h2>
                    <ul>
                        <li>
                            <a href="mailto: hackathon@disruptionnowmedia.com" target="_blank">Hackathon@disruptionnowmedia.com</a>
                        </li>
                        <li>Sponsorship@disruptionnowmedia.com</li>
                        <li>1819 Innovation Hub, Cincinnati OH</li>
                    </ul>
                    <div className="social_icons">
                        <ul>
                            <li>
                                <a href="#">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fa-brands fa-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Agenda;