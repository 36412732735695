let BASE_PATH = "https://api.disrupt.art";
let API_PATH = `${BASE_PATH}/api/v1`;

let MIDWEST_API_PATH = "https://api.midwestcon.live"
export const configPath = {
    baseUrl: BASE_PATH,
    apiUrl: API_PATH,
    eventId : 1,
    midwestApi: MIDWEST_API_PATH,
    stripe_pkey: 'pk_live_mDpRLF0dZTN8MyhAw68XuG7m00Izq12rea'
}
