import React, { useEffect } from 'react';
import { PopupButton } from '@typeform/embed-react';


function DataHeader() {

    const scrollToSection = (target) => {
        const targetElement = document.querySelector(target);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const handleLinkClick = (e, target) => {
        e.preventDefault();
        scrollToSection(target);
    };

    return (
        <header>
            <nav className="navbar navbar-expand-lg fixed-top mwc-navbar data_hack">
                <div className="container">
                    <div className='logo'>
                        <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="/">
                            <img src={'/assets/img/mwclogo.png'} alt="" className="imgcenterimg" />
                            {/* <img src={'/assets/img/midwestcon-logo-new.png'} alt="" className="mw-100" /> */}
                        </a>
                        <div className='sub_logo'>
                            <span>POWERD by</span>
                            <div className='logo_s'>
                                <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="">
                                    <img src={'/assets/img/disruption-now.png'} alt="" className="mw-100" />
                                </a>
                                <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="">
                                    <img src={'/assets/img/for_our_logo.jpg'} alt="" className="mw-100" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-lg-0 ms-auto">
                            <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                                <button className="nav-link " onClick={(e) => handleLinkClick(e, '#data_about')}>ABOUT</button>
                            </li>
                            <li className="nav-item dropdown animated2 animatedFadeInUp fadeInUp">
                                <button className="nav-link  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">AGENDA</button>
                                <ul class="submenu dropdown-menu">
                                    <li>
                                        <div className="blks">
                                            <h5>August 24: Opening Ceremony & Workshops</h5>
                                            <p>This segment includes introductory workshops and initial track-specific breakouts.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="blks">
                                            <h5>August 25-September 3: Virtual Work & Learning</h5>
                                            <p>The virtual learning series and mentor check-ins will take place virtually.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="blks">
                                            <h5>September 4: Conference Day!</h5>
                                            <p>A full day at the Midwestcon Conference on September 4th will be devoted to future of data-related speakers. Judging for the hackathon will begin on September 4th.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="blks">
                                            <h5>September 6: Closing Ceremony</h5>
                                            <p>Winners will get to present their pitches at the closing ceremony of Midwestcon, presenting in front of hundreds of attendees, speakers and mentors.</p>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                                <PopupButton id='ks6FSj5D' className="nav-link nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">VOLUNTEER</PopupButton>
                            </li>
                            <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                                <PopupButton id='bBO2U1RW' className="nav-link nav-link spn_btn" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">SPONSORS</PopupButton>
                            </li>
                            <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                                <PopupButton id='oLC16sAO' className="nav-link nav-link reg_btn" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">REGISTER</PopupButton>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export { DataHeader };
